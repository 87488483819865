import __request__ from 'routing/request';
interface LogFile {
    readonly size: number;
    readonly name: string;
    readonly id: string;
    readonly lastModified: string;
}
type BundleFileArray = BundleFile[];
interface BundleEntries {
    readonly logfiles: LogFile[];
}
interface CallResult_SupportBundleNodeManifestMap {
    readonly [_key: string]: CallResult_SupportBundleNodeManifest;
}
interface CallResult_SupportBundleNodeManifest {
    readonly response: SupportBundleNodeManifest;
    readonly server_error_message: string;
    readonly call_executed: boolean;
}
interface BundleFile {
    readonly size: number;
    readonly file_name: string;
}
interface SupportBundleNodeManifest {
    readonly entries: BundleEntries;
}
/**
 * Build a new Support Bundle
 */
export function buildBundle(): Promise<void> {
    return __request__('POST', '/cluster/debug/support/bundle/build', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Downloads the requested bundle
 * @param filename filename
 */
export function download(filename?: string): Promise<unknown> {
    return __request__('GET', `/cluster/debug/support/bundle/download/${filename}`, null, {}, {
        'Accept': ['application/octet-stream']
    });
}
/**
 * Returns the list of downloadable support bundles
 */
export function listBundles(): Promise<BundleFileArray> {
    return __request__('GET', '/cluster/debug/support/bundle/list', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a certain support bundle
 * @param filename filename
 */
export function remove(filename?: string): Promise<unknown> {
    return __request__('DELETE', `/cluster/debug/support/bundle/${filename}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the Support Bundle Manifest from all nodes in the cluster
 */
export function getClusterManifest(): Promise<CallResult_SupportBundleNodeManifestMap> {
    return __request__('GET', '/cluster/debug/support/manifest', null, {}, {
        'Accept': ['application/json']
    });
}
