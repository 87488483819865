import __request__ from 'routing/request';
interface SuggestionsErrorDTO {
    readonly reason: string;
    readonly type: string;
}
interface TimeRange {
    readonly type: string;
}
interface SuggestionsDTO {
    readonly input: string;
    readonly sum_other_docs_count: number;
    readonly field: string;
    readonly suggestions: SuggestionEntryDTO[];
    readonly error: SuggestionsErrorDTO;
}
interface QueryString {
    readonly query: string;
    readonly last_used: string;
}
interface SuggestionsRequestDTO {
    readonly input: string;
    readonly timerange: TimeRange;
    readonly size: number;
    readonly field: string;
    readonly streams: string[];
}
type QueryStringArray = QueryString[];
interface SuggestionEntryDTO {
    readonly occurrence: number;
    readonly title: string;
    readonly value: string;
}
/**
 * Suggest field value
 */
export function suggestFieldValue(validationRequest?: SuggestionsRequestDTO): Promise<SuggestionsDTO> {
    return __request__('POST', '/search/suggest', validationRequest, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Suggest last used query strings
 * @param limit limit
 */
export function suggestQueryStrings(limit?: number): Promise<QueryStringArray> {
    return __request__('GET', '/search/suggest/query_strings', null, { 'limit': limit }, {
        'Accept': ['application/json']
    });
}
