import __request__ from 'routing/request';
interface QueryStringUsedDTO {
    readonly query_string: string;
}
/**
 * Register a query string used
 */
export function queryStringUsed(queryStringRequest?: QueryStringUsedDTO): Promise<unknown> {
    return __request__('POST', '/search/query_strings', queryStringRequest, {}, {
        'Accept': ['application/json']
    });
}
