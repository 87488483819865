import __request__ from 'routing/request';
interface NamedBinDefinition {
    readonly bin_name: string;
}
interface Histogram {
    readonly schema: string[];
    readonly bins: NamedBinDefinition[];
}
/**
 * Get timerange-based histogram of queries durations and percentage in recent query population
 */
export function getQueryDurationHistogram(): Promise<Histogram> {
    return __request__('GET', '/system/monitoring/query_duration_histogram', null, {}, {
        'Accept': [
            'application/json',
            'text/csv'
        ]
    });
}
