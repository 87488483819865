import __request__ from 'routing/request';
interface IndexRangeSummary {
    readonly took_ms: number;
    readonly end: string;
    readonly calculated_at: string;
    readonly index_name: string;
    readonly begin: string;
}
interface Type {
    readonly type_name: string;
}
interface ChunkedOutput_ResultChunk {
    readonly raw_type: string;
    readonly closed: boolean;
    readonly type: Type;
}
interface SearchDecorationStats {
    readonly added_fields: string[];
    readonly changed_fields: string[];
    readonly removed_fields: string[];
}
interface Object {
}
interface DecorationStats {
    readonly added_fields: {
        readonly [_key: string]: Object;
    };
    readonly changed_fields: {
        readonly [_key: string]: Object;
    };
    readonly removed_fields: {
        readonly [_key: string]: Object;
    };
}
interface ResultMessageSummary {
    readonly decoration_stats: DecorationStats;
    readonly index: string;
    readonly highlight_ranges: {
        readonly [_key: string]: unknown[];
    };
    readonly message: {
        readonly [_key: string]: Object;
    };
}
interface SearchResponse {
    readonly used_indices: IndexRangeSummary[];
    readonly decoration_stats: SearchDecorationStats;
    readonly query: string;
    readonly messages: ResultMessageSummary[];
    readonly from: string;
    readonly to: string;
    readonly time: number;
    readonly built_query: string;
    readonly fields: string[];
    readonly total_results: number;
}
/**
 * Message search with absolute timerange.
 * @param query Query (Lucene syntax)
 * @param from Timerange start. See description for date format
 * @param to Timerange end. See description for date format
 * @param limit Maximum number of messages to return.
 * @param offset Offset
 * @param batch_size Batch size for the backend storage export request.
 * @param filter Filter
 * @param fields Comma separated list of fields to return
 */
export function searchAbsoluteChunked(query: string, from: string, to: string, fields: string, limit?: number, offset?: number, batch_size: number = 500, filter?: string): Promise<ChunkedOutput_ResultChunk> {
    return __request__('GET', '/search/universal/absolute', null, { 'query': query, 'from': from, 'to': to, 'limit': limit, 'offset': offset, 'batch_size': batch_size, 'filter': filter, 'fields': fields }, {
        'Accept': ['text/csv']
    });
}
/**
 * Message search with absolute timerange.
 * @param query Query (Lucene syntax)
 * @param from Timerange start. See description for date format
 * @param to Timerange end. See description for date format
 * @param limit Maximum number of messages to return.
 * @param offset Offset
 * @param filter Filter
 * @param fields Comma separated list of fields to return
 * @param sort Sorting (field:asc / field:desc)
 * @param decorate Run decorators on search result
 */
export function searchAbsolute(query: string, from: string, to: string, limit?: number, offset?: number, filter?: string, fields?: string, sort?: string, decorate: boolean = true): Promise<SearchResponse> {
    return __request__('GET', '/search/universal/absolute', null, { 'query': query, 'from': from, 'to': to, 'limit': limit, 'offset': offset, 'filter': filter, 'fields': fields, 'sort': sort, 'decorate': decorate }, {
        'Accept': ['application/json']
    });
}
/**
 * Export message search with absolute timerange.
 * @param query Query (Lucene syntax)
 * @param from Timerange start. See description for date format
 * @param to Timerange end. See description for date format
 * @param limit Maximum number of messages to return.
 * @param offset Offset
 * @param batch_size Batch size for the backend storage export request.
 * @param filter Filter
 * @param fields Comma separated list of fields to return
 */
export function exportSearchAbsoluteChunked(query: string, from: string, to: string, fields: string, limit?: number, offset?: number, batch_size: number = 500, filter?: string): Promise<unknown> {
    return __request__('GET', '/search/universal/absolute/export', null, { 'query': query, 'from': from, 'to': to, 'limit': limit, 'offset': offset, 'batch_size': batch_size, 'filter': filter, 'fields': fields }, {
        'Accept': ['text/csv']
    });
}
