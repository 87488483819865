import __request__ from 'routing/request';
interface InputStream {
}
interface JsonSchema {
    readonly disallow: JsonSchema[];
    readonly $schema: string;
    readonly readonly: boolean;
    readonly extends: JsonSchema[];
    readonly description: string;
    readonly id: string;
    readonly $ref: string;
    readonly required: boolean;
}
interface ClusterConfigList {
    readonly total: number;
    readonly classes: string[];
}
/**
 * List all configuration classes
 */
export function list(): Promise<ClusterConfigList> {
    return __request__('GET', '/system/cluster_config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get JSON schema of configuration class
 * @param configClass The name of the cluster configuration class
 */
export function schema(configClass: string): Promise<JsonSchema> {
    return __request__('GET', `/system/cluster_config/${configClass}`, null, {}, {
        'Accept': ['application/schema+json']
    });
}
/**
 * Update configuration in database
 * @param configClass The name of the cluster configuration class
 * @param body The payload of the cluster configuration
 */
export function update(configClass: string, body: InputStream): Promise<unknown> {
    return __request__('PUT', `/system/cluster_config/${configClass}`, body, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get configuration settings from database
 * @param configClass The name of the cluster configuration class
 */
export function read(configClass: string): Promise<unknown> {
    return __request__('GET', `/system/cluster_config/${configClass}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete configuration settings from database
 * @param configClass The name of the cluster configuration class
 */
export function remove(configClass: string): Promise<void> {
    return __request__('DELETE', `/system/cluster_config/${configClass}`, null, {}, {
        'Accept': ['application/json']
    });
}
