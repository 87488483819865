// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YV9VLhWAF0dOTkZvWqvg .panel-body {
    max-height: 400px;
    overflow-y: auto;
    padding: 20px 10px;
}

.YV9VLhWAF0dOTkZvWqvg table {
    table-layout: fixed;
    word-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/components/event-definitions/event-definition-types/FilterPreview.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[":local(.filterPreview) .panel-body {\n    max-height: 400px;\n    overflow-y: auto;\n    padding: 20px 10px;\n}\n\n:local(.filterPreview) table {\n    table-layout: fixed;\n    word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterPreview": `YV9VLhWAF0dOTkZvWqvg`
};
export default ___CSS_LOADER_EXPORT___;
