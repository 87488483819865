import __request__ from 'routing/request';
interface IndexSetFieldType {
    readonly is_reserved: boolean;
    readonly origin: 'INDEX' | 'PROFILE' | 'OVERRIDDEN_INDEX' | 'OVERRIDDEN_PROFILE';
    readonly type: string;
    readonly field_name: string;
}
interface MappingRemovalResult {
    readonly failures: BulkOperationFailure[];
    readonly successfully_performed: number;
    readonly errors: string[];
    readonly succeeded: IndexSetFieldType[];
}
interface MappingRemovalResultMap {
    readonly [_key: string]: MappingRemovalResult;
}
interface IndexSetFieldTypeMap {
    readonly [_key: string]: IndexSetFieldType;
}
interface BulkOperationFailure {
    readonly failure_explanation: string;
    readonly entity_id: string;
}
interface CustomFieldMappingRemovalRequest {
    readonly rotate: boolean;
    readonly index_sets: string[];
    readonly fields: string[];
}
interface FieldTypeProfileUnsetRequest {
    readonly rotate: boolean;
    readonly index_sets: string[];
}
interface FieldTypeProfileChangeRequest {
    readonly rotate: boolean;
    readonly index_sets: string[];
    readonly profile_id: string;
}
interface stringMap {
    readonly [_key: string]: string;
}
interface FieldTypeChangeRequest {
    readonly rotate: boolean;
    readonly field: string;
    readonly index_sets: string[];
    readonly type: string;
}
/**
 * Change field type for certain index sets
 */
export function changeFieldType(request?: FieldTypeChangeRequest): Promise<IndexSetFieldTypeMap> {
    return __request__('PUT', '/system/indices/mappings', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove custom field mapping for certain index sets
 */
export function removeCustomMapping(request?: CustomFieldMappingRemovalRequest): Promise<MappingRemovalResultMap> {
    return __request__('PUT', '/system/indices/mappings/remove_mapping', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove field type profile from certain index sets
 */
export function removeProfileFromIndexSets(request?: FieldTypeProfileUnsetRequest): Promise<unknown> {
    return __request__('PUT', '/system/indices/mappings/remove_profile_from', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Set field type profile for certain index sets
 */
export function setProfile(request?: FieldTypeProfileChangeRequest): Promise<unknown> {
    return __request__('PUT', '/system/indices/mappings/set_profile', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get list of all types valid inside the indexer
 */
export function getAllFieldTypes(): Promise<stringMap> {
    return __request__('GET', '/system/indices/mappings/types', null, {}, {
        'Accept': ['application/json']
    });
}
