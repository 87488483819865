// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q3IGkFIUvJ2Wg6SvuD4K {
    margin-top: 10px;
    margin-bottom: 10px;
}

.IsgkvOxhQSEsv8gRjrL3 {
    table-layout: fixed;
}

.A9wyo101mpbrg9i57cBz.alert {
    margin-bottom: 10px;
}

.A9wyo101mpbrg9i57cBz h4 {
    font-size: 1.44rem; /* theme.fonts.size.body h4 */
    margin-bottom: 0.5em;
}

.A9wyo101mpbrg9i57cBz ul {
    list-style: disc;
    margin: 0.5em 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/event-definitions/common/commonStyles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB,EAAE,6BAA6B;IACjD,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[":local(.title) {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n:local(.fixedTable) {\n    table-layout: fixed;\n}\n\n:local(.validationSummary).alert {\n    margin-bottom: 10px;\n}\n\n:local(.validationSummary) h4 {\n    font-size: 1.44rem; /* theme.fonts.size.body h4 */\n    margin-bottom: 0.5em;\n}\n\n:local(.validationSummary) ul {\n    list-style: disc;\n    margin: 0.5em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Q3IGkFIUvJ2Wg6SvuD4K`,
	"fixedTable": `IsgkvOxhQSEsv8gRjrL3`,
	"validationSummary": `A9wyo101mpbrg9i57cBz`
};
export default ___CSS_LOADER_EXPORT___;
