// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m1bai3Kt45u9j3MtW7hT {
    margin-bottom: 20px;
}

.EJT6DlEKjyYuxyouwsaR {
    table-layout: fixed;
}
`, "",{"version":3,"sources":["webpack://./src/components/event-definitions/event-definition-form/field-value-providers/CommonFieldValueProviderSummary.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[":local(.field) {\n    margin-bottom: 20px;\n}\n\n:local(.fixedTable) {\n    table-layout: fixed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `m1bai3Kt45u9j3MtW7hT`,
	"fixedTable": `EJT6DlEKjyYuxyouwsaR`
};
export default ___CSS_LOADER_EXPORT___;
